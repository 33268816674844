import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'

$(() => {
  $('body').on('click', '.confirm-reminder', (e) => {
    const {
      url,
      title,
      body,
      confirmButtonText = title,
    } = e.currentTarget.dataset

    Swal.fire({
      ...defaultSwalParams,
      title,
      html: `<div class="simple-modal-body delete-modal-body"><p>${body}</p></div>`,
      confirmButtonText,
      cancelButtonText: 'Never mind',
    }).then((result) => {
      if (result.isConfirmed) {
        $(
          `<form method="post" action="${url}">
            <input type="hidden" name="_method" value="POST">
            <input type="hidden" name="authenticity_token" value="${window.authenticity_token}">
          </form>`,
        )
          .appendTo('body')
          .trigger('submit')
      }
    })
  })
})
