import * as $ from 'jquery'
import '@selectize/selectize'
import moment from 'moment'
import 'jquery-datetimepicker'
import Inputmask from 'inputmask'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'

declare global {
  interface Window {
    initDatePicker: () => void
    initTimePicker: () => void
    initNumbersOnly: () => void
    initTimeRange: () => void
    initMaxLengthInputs: () => void
    initMasks: () => void
    showConfirmation: (title: string, description: string) => Promise<boolean>
  }
}

function createNewEvent(eventName, params) {
  let event
  if (typeof Event === 'function') {
    event = new Event(eventName, params)
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, params.bubbles, true)
  }
  return event
}

const simulateReactEvent = (input) => {
  const event = createNewEvent('change', { bubbles: true })

  const tracker = input._valueTracker
  if (tracker) {
    tracker.setValue(new Date())
  }

  input.dispatchEvent(event)
}

$(() => {
  $('.select').each((_, element) => {
    $(element).selectize({
      allowEmptyOption: element.dataset.allowEmptyOption === 'true',
    })
  })
  $('.timezones-select').each((_, object) => {
    if (object.dataset?.options) {
      const options = JSON.parse(object.dataset.options)
      const $select = $(object).selectize({
        options,
        valueField: 'tzid',
        labelField: 'abbreviation',
        searchField: ['offset_string', 'abbreviation'],
        render: {
          option: (item, escape) =>
            `<div class="option">${escape(item.offset_string)}</div>`,
        },
        onChange(newTimezone) {
          $('.readonly-timezone').val(
            options.find((option) => option.name === newTimezone)
              ?.abbreviation || '',
          )
        },
      })
      const selectize = $select[0].selectize
      if (object.dataset.defaultvalue) {
        selectize.setValue(object.dataset.defaultvalue)
      }
    }
  })

  Inputmask.extendAliases({
    'https-url': {
      regex: 'https://.*',
      autoUnmask: false,
      keepStatic: false,
      tabThrough: true,
    },
  })
  window.initDatePicker()
  window.initTimePicker()
  window.initNumbersOnly()
  window.initTimeRange()
  window.initMaxLengthInputs()
  window.initMasks()
})

let timeFromValueAbbr = ''

window.moment = moment
$.datetimepicker.setDateFormatter({
  parseDate: function (date, format) {
    const allowedFormats = [
      {
        format: 'MM/DD/YY',
        regex: /\d{1,2}([,.\/])\s?\d{1,2}([,.\/])\s?\d{2}$/,
      },
      {
        format: 'MM/DD/YYYY',
        regex: /\d{1,2}([,.\/])\s?\d{1,2}([,.\/])\s?\d{4}$/,
      },
    ]

    const allowedFormat = allowedFormats.find(
      (formatObj) => !!date.match(formatObj.regex),
    )

    const dateToParse =
      timeFromValueAbbr && !date.match(/[a-z]{2}/g)
        ? `${date}${timeFromValueAbbr}`
        : date

    const d = moment(dateToParse, allowedFormat?.format || format)
    timeFromValueAbbr = ''
    return d.isValid() ? d.toDate() : false
  },

  formatDate: function (date, format) {
    return moment(date).format(format)
  },

  formatMask: function (format) {
    return format
      .replace(/Y{4}/g, '9999')
      .replace(/Y{2}/g, '99')
      .replace(/M{2}/g, '19')
      .replace(/D{2}/g, '39')
      .replace(/H{2}/g, '29')
      .replace(/m{2}/g, '59')
      .replace(/s{2}/g, '59')
  },
})
$.datetimepicker.setLocale('en')
const formatTime = 'h:mma'

window.initDatePicker = () => {
  $('.datepicker').datetimepicker({
    scrollMonth: false,
    scrollInput: false,
    timepicker: false,
    format: 'ddd, MMM Do, YYYY',
    formatDate: 'ddd, MMM Do, YYYY',
    onChangeDateTime: (newValue, input) => {
      simulateReactEvent(input[0])
    },
  })
}
const getMinTime = (element: HTMLElement, startOfHour?: boolean) => {
  const parent = $(element).closest('.time-range-inputs')
  const timeFrom = parent.find('.time-from')

  return timeFrom.val()
    ? (startOfHour
        ? moment(timeFrom.val(), formatTime).add(1, 'minute').startOf('hour')
        : moment(timeFrom.val(), formatTime).add(1, 'minute')
      ).format(formatTime)
    : '12:00am'
}

window.initTimePicker = () => {
  Array.from($('.timepicker')).forEach((element) => {
    const maxTime = $(element).hasClass('time-from') ? '11:58pm' : '11:59pm'
    $(element).datetimepicker({
      datepicker: false,
      formatTime,
      format: formatTime,
      maxTime,
      minTime: $(element).hasClass('time-to')
        ? getMinTime(element, true)
        : false,
      onChangeDateTime: (newValue, input) => {
        const timeForm = $(element)
          .closest('.time-range-inputs')
          .find('.time-from')
        if ($(element).hasClass('time-to') && timeForm?.val()) {
          const minTime = getMinTime(timeForm[0])

          if (
            $(input).val() === minTime ||
            moment($(input).val(), formatTime).isBefore(
              moment(minTime, formatTime),
            )
          ) {
            $(input).val(moment(minTime, formatTime).format(formatTime))
          }
        }

        if (
          moment($(input).val(), formatTime).isAfter(
            moment(maxTime, formatTime),
          )
        ) {
          $(input).val(maxTime)
        }

        simulateReactEvent(input[0])
      },
    })
  })
}

window.initNumbersOnly = () => {
  $('body').on('input', '.numbers-only', (e) => {
    let newValue = e.target.value
    let isNegative = false

    if (newValue[0] === '-' && e.target.dataset.allowNegative) {
      newValue = newValue.substr(1)
      isNegative = true
    }

    if (newValue.match(/\D/g)) {
      newValue = newValue.replace(/\D/g, '')
    }

    if (newValue) {
      newValue = parseInt(newValue)

      const maxAllowedValue = e.target.dataset && parseInt(e.target.dataset.max)

      if (maxAllowedValue && newValue > maxAllowedValue) {
        newValue = maxAllowedValue
      }
    }

    $(e.target).val(isNegative ? `-${newValue}` : newValue)

    simulateReactEvent(e.target)
  })
}

window.initTimeRange = () => {
  const ampmHackCallback = (e) => {
    const timeFromVal = $(e.target)
      .closest('.input-component-wrapper')
      .find('.time-from')
      .val()
    timeFromValueAbbr = (timeFromVal as string).match(/[a-z]{2}/g)?.[0] || ''
  }
  $('.time-range-inputs .timepicker.time-to').off('change', ampmHackCallback)
  $('.time-range-inputs .timepicker.time-to').on('change', ampmHackCallback)

  $('.time-range-inputs').on('change', '.timepicker', (e) => {
    if ($(e.target).hasClass('time-from')) {
      const parent = $(e.target).closest('.time-range-inputs')
      const timeTo = parent.find('.time-to')

      const minTime = getMinTime(e.target)

      timeTo.datetimepicker({
        formatTime,
        minTime: getMinTime(e.target, true),
        onChangeDateTime: (newValue, input) => {
          if (
            $(input).val() === minTime ||
            moment($(input).val(), formatTime).isBefore(
              moment(minTime, formatTime),
            )
          ) {
            $(input).val(moment(minTime, formatTime).format(formatTime))
          }
          simulateReactEvent(input[0])
        },
      })

      timeTo.val('')
      timeTo.trigger('change')
    }
  })
}

window.initMaxLengthInputs = () => {
  $('.has-max-length').each((_, element) => {
    const input = $(element)
    const inputWrapper = input.parent()
    input.off('input')
    const maxLength = parseInt(input.data('max-length'))
    if (!inputWrapper.find('.chars-count').length) {
      inputWrapper.append($(`<div class="chars-count"></div>`))
    }

    function fillCurrentCharsLength() {
      inputWrapper
        .find('.chars-count')
        .html(`${(input.val() as string).length} / ${maxLength} characters`)
    }

    fillCurrentCharsLength()

    input.on('input', (e) => {
      if ((e.target as HTMLInputElement).value.length > maxLength) {
        input.val((e.target as HTMLInputElement).value.substring(0, maxLength))
      }

      simulateReactEvent(e.target)
      fillCurrentCharsLength()
    })
  })
}

window.initMasks = () => {
  const inputs = document.querySelectorAll('input.mask')
  Inputmask().mask(inputs)
  const setCaretPosition = (e) => {
    const element = e.currentTarget
    const valueLength = element.value.length
    element.setSelectionRange(valueLength, valueLength)
  }
  $(inputs).off('focus', setCaretPosition)
  $(inputs).on('focus', setCaretPosition)
}

window.showConfirmation = (title, body) =>
  Swal.fire({
    ...defaultSwalParams,
    title: title,
    html: `<div class="simple-modal-body">
      <p>
        ${body}
      </p>
    </div>`,
  }).then((result) => result.isConfirmed)
