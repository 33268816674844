import $ from 'jquery'
import axios from 'axios'
import Swal, { SweetAlertResult } from 'sweetalert2'

// interfaces
import {
  IOrganization,
  ICommunity,
  TCommunitySubscriberNotificationFrequency,
  TCommunitySubscriberAllyAffiliate,
  toAllyAffiliate,
} from 'types'

import alliesIcon from 'images/icons/allies.svg'

import { defaultSwalParams, escapeHTML } from './general'

const ManageNotificationsModal = Swal.mixin({
  ...defaultSwalParams,
  showCancelButton: false,
})

const SubscriptionPreferencesModal = Swal.mixin({
  ...defaultSwalParams,
  customClass: {
    ...defaultSwalParams.customClass,
    header: 'neutral',
  },
  showCancelButton: false,
})

interface IUpdateSubscription {
  id: number
  notificationFrequency?: TCommunitySubscriberNotificationFrequency
  allyAffiliate?: TCommunitySubscriberAllyAffiliate
}

const updateSubscription = async ({
  id,
  notificationFrequency,
  allyAffiliate,
}: IUpdateSubscription) => {
  try {
    const {
      data: { data },
    } = await axios.patch(`/dashboard/community_subscriptions/${id}`, {
      authenticity_token: window.authenticity_token,
      _method: 'patch',
      community_subscriber: {
        ally_affiliate: allyAffiliate,
        notification_frequency: notificationFrequency,
      },
    })

    return data
  } catch (e) {
    window.flash('Something went wrong!', 'alert')
    return { e }
  }
}

// The SVG has been copied from app/webpacker/images/icons/radio.svg
const allyAffiliateRadio = (value, label) => `
    <div class="input-component-wrapper checkbox-component-wrapper">
    <label class="label" for="ally_affiliate_option_${value}">${label}</label>
    <label class="input-wrapper radio" tabindex="0" for="ally_affiliate_option_${value}">
        <input id="ally_affiliate_option_${value}" type="radio" value="${value}" name="ally_affiliate_option">
        <svg viewBox="1 1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="8" height="8" rx="4" fill="white"/>
        </svg>        
    </label>
    </div>`

const showAllyAffiliatesModal = async (
  organization: IOrganization,
): Promise<TCommunitySubscriberAllyAffiliate> => {
  const { isConfirmed } = await SubscriptionPreferencesModal.fire({
    title: 'One last thing!',
    html: `
        <div class="simple-modal-body register-modal-body share-event-modal community-subscription-preferences-modal">
            <p>
              Before you go, ${escapeHTML(
                organization.title,
              )} would like to know whether you're joining as an ally or affiliate of this group</p>
            <p class="disclaimer caption">
              Disclosing this is totally optional (you can select 'I would prefer not to disclose') and the information 
              will only be visible to ${escapeHTML(
                organization.title,
              )} in an anoymous, aggregated way to help ${escapeHTML(
      organization.title,
    )} understand the overall makeup of this community.
            </p>
            ${allyAffiliateRadio('affiliate', 'I identify with this community')}
            ${allyAffiliateRadio('ally', 'I am an ally to this community')}
            ${allyAffiliateRadio(
              'no_designation',
              'I would prefer not to disclose',
            )}
        </div>`,
    confirmButtonText: 'Save',
    didOpen: (popup) => {
      Swal.disableButtons()
      $('[name="ally_affiliate_option"]', popup).on('change', () => {
        if ($('[name="ally_affiliate_option"]:checked', popup).length) {
          Swal.enableButtons()
        }
      })
    },
  })
  if (isConfirmed) {
    const checked = $('[name="ally_affiliate_option"]:checked')
    return checked ? toAllyAffiliate(`${checked.val()}`) : undefined
  }
  return undefined
}
const switchHTML = (selectedOption) => `
<div class="input-component-wrapper additional-inputs">
  <label class="label">Receive emails for new events and calls to action?</label>
  <div class="switch-wrapper">
    <p class="off">No</p>
    <div class="input-component-wrapper switch-component-wrapper">
      <div class="input-wrapper">
        <label for="notification_frequency" tabindex='0' class="switch yes-no">
          <input type="checkbox" id="notification_frequency" name="notification_frequency" ${
            selectedOption === 'every_update' ? 'checked="checked"' : ''
          }>
          <span class="back"></span>
          <i class="indicator"></i>
        </label>
      </div>
    </div>
    <p class="on">Yes</p>
  </div>
</div>
`

const getNotificationFrequencyValue = () =>
  $('#notification_frequency').prop('checked') ? 'every_update' : 'no_updates'

export const showManageNotificationsModal = async (
  community: ICommunity,
  selectedNotificationFrequency: TCommunitySubscriberNotificationFrequency,
): Promise<{ message: string; row: number }> =>
  ManageNotificationsModal.fire({
    title: 'Manage Notifications',
    html: `
        <div class="simple-modal-body community-subscription-preferences-modal">
            <p>If you opt-in for email updates, you will be notified when new events and calls to action are created in this community. It is the best way to stay up to date with the communities you are subscribed to.</p>
            ${switchHTML(selectedNotificationFrequency)}
        </div>`,
    confirmButtonText: 'Save',
  }).then(async ({ isConfirmed }) => {
    if (isConfirmed) {
      const { message, row } = await updateSubscription({
        id: community.id,
        notificationFrequency: getNotificationFrequencyValue(),
      })

      return { message, row }
    }
    return null
  })

export const showPostSubscriptionModal = async (
  community: ICommunity,
  organization: IOrganization,
): Promise<{ message: string; row: number }> => {
  const { isConfirmed } = await SubscriptionPreferencesModal.fire({
    title: `Welcome to the community, ${window.currentUser.first_name}!`,
    html: `
        <div class="simple-modal-body register-modal-body share-event-modal community-subscription-preferences-modal">
            <p>Thanks for joining. We look forward to welcoming you to our events.</p>
            <h1 class="community-title">${escapeHTML(community.name)}</h1>
            <h2 class="community-subtitle">${escapeHTML(
              community.subtitle,
            )}</h2>
            <div class="info">
                ${
                  community.allies_welcome
                    ? `<div class="allies">
                            <svg>
                                <use href="${alliesIcon}#allies"></use>
                            </svg>
                        </div>`
                    : ''
                }
            </div>
            ${switchHTML('every_update')}
        </div>`,
    confirmButtonText: organization.alliesAttributesFeatureEnabled
      ? 'Next'
      : 'Save',
  })
  if (isConfirmed) {
    const notificationFrequency = getNotificationFrequencyValue()
    const allyAffiliate = organization.alliesAttributesFeatureEnabled
      ? await showAllyAffiliatesModal(organization)
      : undefined
    const { message, row } = await updateSubscription({
      id: community.id,
      notificationFrequency,
      allyAffiliate,
    })

    return { message, row }
  }
  return null
}

export const showUnsubscribeConfirmation = (
  type: 'cohort' | 'community',
): Promise<SweetAlertResult> => {
  if (type === 'cohort') {
    return Swal.fire({
      ...defaultSwalParams,
      title: 'Are you sure you want to leave this cohort?',
      html: `<div class="simple-modal-body">
        <p>If you leave, you’ll no longer able to see this cohort from your homepage and you will be unregistered from any upcoming events and calls to action.</p>
      </div>`,
      confirmButtonText: 'Leave',
    })
  } else {
    return Swal.fire({
      ...defaultSwalParams,
      title: 'Are you sure you want to leave this community?',
      html: `<div class="simple-modal-body">
        <p>If you leave, you’ll no longer be counted as part of this community and you won’t receive email updates about the community’s events or calls to action.</p>
      </div>`,
      confirmButtonText: 'Leave',
    })
  }
}
