// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()
import 'channels'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../images/', true)

import 'core-js'

import '../javascripts/flash'
import '../javascripts/general'
import '../javascripts/onboarding'
import '../javascripts/home'
import '../javascripts/crop'
import '../javascripts/special-inputs'
import '../javascripts/tabs'
import '../javascripts/forms'
import '../javascripts/community-subscriptions'
import '../javascripts/event-call-subscriptions'
import '../javascripts/delete-modal'
import '../javascripts/reminder-modal'
import '../javascripts/email-leader-modal'
import '../javascripts/edit-log-modal'
import '../javascripts/google-autocomplete'
import '../javascripts/view-bio'
import '../javascripts/detail-views'
import '../javascripts/badges'
import '../javascripts/reset-password-modal'
import '../javascripts/periodic-digest-switch'
import '../javascripts/deactivate-org-modal'
import '../javascripts/cohort-participant-modals'
import '../javascripts/unknown-affiliations-modal'
import '../javascripts/organization-auth-tokens'
import '../javascripts/recurring'
