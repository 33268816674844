import * as $ from 'jquery'

declare global {
  interface Window {
    initAutocomplete: () => void
  }
}

window.initAutocomplete = () => {
  $('.address').each(function (index, element) {
    const $element = $(element)
    function getInputValue() {
      try {
        const inputObject = JSON.parse($element.val() as string)
        return inputObject.full_address
      } catch (e) {
        return ''
      }
    }

    const inputValue = getInputValue()

    if ($element.attr('data-initialized')) {
      if (!$element.val()) {
        const autocompleteWrapper = $element
          .parent()
          .find('.address-field-wrapper')
        autocompleteWrapper.find('input').val('')
      }
    } else {
      $element.attr('data-initialized', 'true')

      const errors = element.dataset.errors

      const autocompleteInput = $('<input type="text" class="input"/>')
      autocompleteInput.val(inputValue)
      autocompleteInput.attr('required', $element.attr('required'))
      autocompleteInput.attr('readonly', $element.attr('readonly'))
      autocompleteInput.attr('disabled', $element.attr('disabled'))
      autocompleteInput.attr('placeholder', 'Please enter the street address')
      autocompleteInput.insertAfter(element)

      const fieldWrapper = $('<div class="address-field-wrapper"></div>')
      if (errors) {
        autocompleteInput.addClass('validation-error')
        autocompleteInput
          .closest('.input-wrapper')
          .append($(`<span class="validation-error-message">${errors}</span>`))
      }

      if (!$element.attr('readonly') && !$element.attr('disabled')) {
        autocompleteInput.wrap(fieldWrapper)

        const autocompleteParams = {
          fields: ['address_components', 'formatted_address', 'geometry'],
        }

        const autocomplete = new window.google.maps.places.Autocomplete(
          autocompleteInput[0] as HTMLInputElement,
          autocompleteParams,
        )

        window.google.maps.event.addDomListener(
          autocompleteInput[0],
          'keydown',
          function (event) {
            if ((event as KeyboardEvent).key === 'Enter') {
              event.preventDefault()
            }
          },
        )

        // autocomplete.setComponentRestrictions({ country: ['us'] })
        autocomplete.addListener('place_changed', function () {
          const rawData = autocomplete.getPlace().address_components

          if (rawData) {
            const findFieldValue = (fieldName) => {
              const requestedObj = rawData.find((dataElement) =>
                dataElement.types.includes(fieldName),
              )
              return requestedObj ? requestedObj.short_name : ''
            }

            const findFieldValueByLongName = (fieldName) => {
              const requestedObj = rawData.find((dataElement) =>
                dataElement.types.includes(fieldName),
              )
              return requestedObj ? requestedObj.long_name : ''
            }

            const country = findFieldValueByLongName('country')
            const adm1 = findFieldValue('administrative_area_level_1')
            const adm2 = findFieldValue('administrative_area_level_2')
            const city =
              findFieldValueByLongName('locality') ||
              findFieldValueByLongName('postal_town') ||
              findFieldValueByLongName('sublocality_level_1')
            // test address
            // const full_address = autocomplete.getPlace().formatted_address
            const full_address = autocompleteInput.val()

            $element.val(
              JSON.stringify({
                country: country,
                adm1: adm1,
                adm2: adm2,
                city: city,
                full_address: full_address,
              }),
            )
            $element.trigger('change')

            // test address
            // autocompleteInput.val(full_address)
          }
        })

        autocompleteInput.on('input', () => {
          $element.val('')
        })

        $element.closest('.input-wrapper').find('.loading-text').remove()
      } else {
        $element.closest('.input-wrapper').find('.loading-text').remove()
      }
    }
  })
}
