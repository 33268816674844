import 'cropperjs'
import 'jquery-cropper'
import $ from 'jquery'
import Swal from 'sweetalert2'
import convert from 'xml-js'

import {
  asyncFilesUpload,
  changeLoadingFormStatusJQuery,
  defaultSwalParams,
} from './general'
interface ICropped extends HTMLElement {
  cropper: Cropper
}

$(() => {
  $('body').on('click', '.to-be-cropped-wrapper .remove', (e) => {
    const wrapper = $(e.target).closest('.to-be-cropped-wrapper')
    wrapper.find('.to-be-cropped').val('')
    wrapper.find('.preview').addClass('hidden')
    wrapper.find('.link').removeClass('hidden')
    wrapper.find('#add-image-button').removeClass('hidden')
    wrapper.find('.destroy-field').val('true')
  })

  const aspectRatios = {
    banner: 95 / 12,
    tile: 77 / 42,
  }

  const validate = ($input, error) => {
    if (error) {
      const labelWrapper = $input.closest('label')
      if (labelWrapper.hasClass('flash-error')) {
        window.flash(error, 'alert')
      } else {
        let errorContainer = labelWrapper.find('.validation-error-message')

        errorContainer = errorContainer?.length
          ? errorContainer
          : $('<span/>')
              .addClass('validation-error-message')
              .appendTo(labelWrapper)
        errorContainer.text(error)

        $input
          .closest('.input-component-wrapper')
          .find('label')
          .first()
          .addClass('validation-error')
        labelWrapper.addClass('has-error')
      }
      return false
    }
    return true
  }

  const validateFile = (file) => {
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      return 'Select only .jpg, .jpeg, .png, .gif files'
    }
    if (file.size > 10 * 1024 * 1024) {
      return 'File too large'
    }
  }

  $('body').on('change', '.to-be-cropped', (e) => {
    const input = e.target as HTMLInputElement
    const inputJquery = $(e.target)
    const imageType = e.target.dataset.imageType
    let isCropping = false
    if (input.value) {
      const file = input.files[0]
      if (validate(inputJquery, validateFile(file))) {
        Swal.fire({
          ...defaultSwalParams,
          width: 572,
          title: `${e.target.dataset.textTitle || 'Add Logo'}`,
          confirmButtonText: `${e.target.dataset.submitButton || 'Select'}`,
          allowOutsideClick: () => !isCropping,
          html: `<div class="custom-cropper-modal">
            <div class="custom-cropper">
            <div class="cropper-preview ${
              imageType || 'round'
            }">Loading...</div>
          </div>`,
          didOpen: () => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function () {
              $('.cropper-preview')
                .html('')
                .append($('<img>', { src: reader.result as string }))
              const cropper = $('.cropper-preview img')
              cropper.cropper({
                viewMode: 1,
                aspectRatio: aspectRatios[imageType] || 1,
                autoCropArea: imageType === 'banner' ? 1 : 0.8,
              })
              cropper.on('cropstart', () => {
                isCropping = true
              })
              cropper.on('cropend', () => {
                setTimeout(() => {
                  isCropping = false
                }, 0)
              })
            }
          },
          preConfirm: () => {
            const wrapper = inputJquery.closest('.to-be-cropped-wrapper')
            const hiddenField = wrapper.find('.hidden-file-field')
            const cropper = ($('.cropper-preview img')[0] as ICropped).cropper
            const fileName = input.files[0].name
            const fileType = input.files[0].type
            const form = inputJquery.closest('form')
            changeLoadingFormStatusJQuery(form)

            wrapper.find('label .link').addClass('hidden')
            wrapper.find('#add-image-button').addClass('hidden')
            wrapper.find('.preview').removeClass('hidden')
            inputJquery.val('')
            cropper.getCroppedCanvas().toBlob(async (blob) => {
              wrapper.find('.preview').addClass('loading')
              if (!wrapper.find('#logo-img').length) {
                wrapper
                  .find('.preview')
                  .empty()
                  .append(
                    e.target.dataset.textPreview === 'true'
                      ? `<div class="link image">
                <span>
                  ${fileName}
                </span>
                <button class="not-styled-button remove" aria-label="remove file" type="button"></button>
              </div>`
                      : `<div class="image-container">
                <img src=${URL.createObjectURL(blob)} />
                <button class="not-styled-button remove absolute" type="button" aria-label="remove image"></button>
              </div>`,
                  )
              }
              wrapper.find('img').attr({ src: URL.createObjectURL(blob) })
              try {
                const fileJSON = await asyncFilesUpload(
                  blob,
                  fileName,
                  fileType,
                )

                const fileJSONValue = JSON.stringify({
                  ...fileJSON,
                  preview: URL.createObjectURL(blob),
                })

                hiddenField
                  .val(fileJSONValue)
                  .trigger('change', { fileJSON: fileJSONValue })

                // hack for react onChange
                ;(hiddenField[0] as any)._valueTracker?.setValue('')
                hiddenField[0].dispatchEvent(
                  new Event('input', { bubbles: true }),
                )

                wrapper.find('.destroy-field').val('')
              } catch (error) {
                wrapper.find('.to-be-cropped').val('')
                wrapper.find('.link').removeClass('hidden')
                wrapper.find('#add-image-button').removeClass('hidden')
                try {
                  window.flash(
                    JSON.parse(
                      convert.xml2json(error.response?.data, { compact: true }),
                    ).Error.Message._text,
                    'alert',
                  )
                } catch (e) {
                  window.flash(
                    'Something went wrong with images upload!',
                    'alert',
                  )
                }
              }
              changeLoadingFormStatusJQuery(form, true)
              wrapper.find('.preview').removeClass('loading')
            }, fileType)
            return true
          },
        }).then(({ isConfirmed }) => {
          if (!isConfirmed) {
            input.value = null
          }
        })
      }
    }
  })
})
