import $ from 'jquery'
import 'slick-carousel'

$(function () {
  //community landing page slider
  $('.community-events-slider').slick({
    draggable: false,
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: false,
    nextArrow: $('.slick-arrow.slick-next'),
    prevArrow: $('.slick-arrow.slick-prev'),
  })

  //community LP team slider
  $('.community-team-slider').slick({
    draggable: false,
    slidesToScroll: 1,
    slidesToShow: 3,
    autoplay: false,
    nextArrow: $('.slick-arrow.team.slick-next'),
    prevArrow: $('.slick-arrow.team.slick-prev'),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1175,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })

  //community LP images slider
  $('.community-images-slider').slick({
    draggable: false,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: false,
    nextArrow: $('.slick-arrow.img.slick-next'),
    prevArrow: $('.slick-arrow.img.slick-prev'),
  })

  $('.community-yearbook-slider').slick({
    draggable: false,
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 3,
    autoplay: false,
    nextArrow: $('.slick-arrow.yearbook.slick-next'),
    prevArrow: $('.slick-arrow.yearbook.slick-prev'),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1175,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })
})
