import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'

$(() => {
  $('body').on('click', '.view-bio', (e) => {
    const speaker = JSON.parse(e.currentTarget.dataset.speaker)

    Swal.fire({
      ...defaultSwalParams,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        header: 'bio-header',
        closeButton: 'modal close',
      },
      html: `<div class="speaker-bio-modal">
        <div class="speaker-info">
          <img src="${speaker.avatar}" alt="${speaker.name}">
          <div>
            <h3>${speaker.name}</h3>
            <p>${[speaker.title, speaker.company].join(', ')}</p>
          </div>
        </div>
        <p class="bio-text">${speaker.bio}</p>
      </div>`,
    })
  })
})
