import $ from 'jquery'

declare global {
  interface Window {
    flash: (message: string, type?: string) => void
  }
}

const hideAfterTimeout = () => {
  if (
    $('.flash-notification').length &&
    $('.flash-notification').is(':visible')
  ) {
    setTimeout(() => {
      $('.flash-notification').slideUp()
    }, 5000)
  }
}

$(() => {
  $('body').on('click', '.flash-notification .close', (e) => {
    $(e.currentTarget).closest('.flash-notification').slideUp()
  })

  hideAfterTimeout()

  $('body').on('click', '.flash_type_preview .close', () => {
    window.close()
  })
})

window.flash = (message, type = 'success') => {
  const flashContainer = $(`.flash_type_${type}`)
  flashContainer.find('.message').text(message)
  flashContainer.slideDown()

  hideAfterTimeout()
}
