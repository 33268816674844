import Swal, { SweetAlertResult } from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'
import $ from 'jquery'

// assets
import supporter from 'images/icons/badges/supporter.svg?url'
import advocate from 'images/icons/badges/advocate.svg?url'
import volunteer from 'images/icons/badges/volunteer.svg?url'
import delegate from 'images/icons/badges/delegate.svg?url'
import conversation_starter from 'images/icons/badges/conversation_starter.svg?url'
import thought_leader from 'images/icons/badges/thought_leader.svg?url'
import connector from 'images/icons/badges/connector.svg?url'
import ambassador from 'images/icons/badges/ambassador.svg?url'
import community_leader from 'images/icons/badges/community_leader.svg?url'
import intersectional_leader from 'images/icons/badges/intersectional_leader.svg?url'
import linkedinLogo from 'images/icons/linkedin_14.png'

export type Badge =
  | 'supporter'
  | 'advocate'
  | 'conversation_starter'
  | 'thought_leader'
  | 'volunteer'
  | 'delegate'
  | 'connector'
  | 'ambassador'
  | 'community_leader'
  | 'intersectional_leader'

type TBadgeInfo = {
  title: string
  description: string
  icon: string
  url: string
}

type TBadges = {
  [key in Badge]: TBadgeInfo
}

const badgesInfo: TBadges = {
  supporter: {
    title: 'Supporter: 5 Events',
    description: 'You earned this badge by registering for 5 different events.',
    icon: supporter,
    url: 'https://www.mindrglobal.com/badges/supporter',
  },
  advocate: {
    title: 'Advocate: 10 Events',
    description:
      'You earned this badge by registering for 10 different events.',
    icon: advocate,
    url: 'https://www.mindrglobal.com/badges/advocate',
  },
  conversation_starter: {
    title: 'Conversation Starter: 5 Suggestions',
    description: 'You earned this badge by submitting 5 suggestions to events.',
    icon: conversation_starter,
    url: 'https://www.mindrglobal.com/badges/conversation-starter',
  },
  thought_leader: {
    title: 'Thought Leader: 10 Suggestions',
    description:
      'You earned this badge by submitting 10 suggestions to events.',
    icon: thought_leader,
    url: 'https://www.mindrglobal.com/badges/thought-leader',
  },
  volunteer: {
    title: 'Volunteer: 5 Calls to Action',
    description:
      'You earned this badge by registering for 5 different calls to action.',
    icon: volunteer,
    url: 'https://www.mindrglobal.com/badges/volunteer',
  },
  delegate: {
    title: 'Delegate: 10 Calls to Action',
    description:
      'You earned this badge by registering for 10 different calls to action.',
    icon: delegate,
    url: 'https://www.mindrglobal.com/badges/delegate',
  },
  connector: {
    title: 'Connector: Invite Others to 5 Events or Calls to Action',
    description:
      'You earned this badge by inviting people to 5 events or calls to action.',
    icon: connector,
    url: 'https://www.mindrglobal.com/badges/connector',
  },
  ambassador: {
    title: 'Ambassador: Invite Others to 10 Events or Calls to Action',
    description:
      'You earned this badge by inviting people to 10 events or calls to action.',
    icon: ambassador,
    url: 'https://www.mindrglobal.com/badges/ambassador',
  },
  community_leader: {
    title: 'Community Leader: Lead a Community',
    description: 'You earned this badge by leading a community.',
    icon: community_leader,
    url: 'https://www.mindrglobal.com/badges/community-leader',
  },
  intersectional_leader: {
    title: 'Intersectional Leader: Lead 2 or More Communities',
    description: 'You earned this badge by leading two communities.',
    icon: intersectional_leader,
    url: 'https://www.mindrglobal.com/badges/intersectional-leader',
  },
}

export const showBadgesModal = async (
  badge: Badge,
  isAchieved: boolean,
  isViewBadge?: boolean,
): Promise<SweetAlertResult> => {
  const { icon, title, description, url } = badgesInfo[badge]

  const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url,
  )}`

  const shareButton = window.organization?.shareBadgeEnabled
    ? `<a
      href="${shareUrl}"
      class="button secondary share-button"
      target="_blank"
      rel="noopener noreferrer">
      <img class="share-logo" src="${linkedinLogo}" />
      Share
    </a>`
    : ''

  return await Swal.fire({
    ...defaultSwalParams,
    showCancelButton: false,
    title:
      isAchieved &&
      `Congratulations, ${window.currentUser.first_name}, your hard work is being noticed.`,
    html: `<div class="badges-modal-body ${isAchieved ? '' : 'no-background'}">
      <img src=${icon} width="280" height="280" />
      <h2>${title}</h2>
      ${
        isAchieved
          ? `<p>${description}</p>
            ${shareButton}`
          : ''
      }
      ${
        !isViewBadge
          ? '<a href="/dashboard/badges" class="link">See all of the other badges you can earn.</a>'
          : ''
      }
    </div>`,
    confirmButtonText: 'Close',
    width: 'auto',
    showConfirmButton: !isViewBadge,
  })
}

$(() => {
  $('body').on('click', '.view-badge', (e) => {
    const { badge, achieved } = e.currentTarget.dataset
    showBadgesModal(badge, achieved === 'true', true)
  })
})
