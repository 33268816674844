import $ from 'jquery'
import Swal from 'sweetalert2'
import Axios from 'axios'

import { defaultSwalParams } from 'javascripts/general'
import userPicker from 'javascripts/user-picker'

$(() => {
  const reloadPage = () => {
    $('.reload-page-hack').trigger('click')
  }

  $('body').on('click', '.remove-cohort-participant-button', (e) => {
    const { fullName, userId, url, isLeader } = e.currentTarget.dataset

    const body =
      isLeader === 'true'
        ? `Are you sure you want to remove ${fullName} from this cohort? They will lose access to administer this cohort and to all upcoming events and calls to actions in this cohort.`
        : `Are you sure you want to remove ${fullName} from this cohort? They will lose access to all upcoming events and calls to actions in this cohort.`

    Swal.fire({
      ...defaultSwalParams,
      title: isLeader === 'true' ? 'Remove Leader' : 'Remove Participant',
      html: `<div class="simple-modal-body">
        <p>${body}</p>
        <br/>
        <p>They will not be able to rejoin unless you or another administrator of this cohorts adds them to the cohort again.</p>
      </div>`,
      confirmButtonText: 'Remove',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const { data } = await Axios.post(url, {
            user_ids: [userId],
            authenticity_token: window.authenticity_token,
          })
          window.flash(
            data?.data?.message ||
              `Successfully removed ${fullName} from this cohort.`,
          )
          reloadPage()
        } catch (ex) {
          window.flash('Something went wrong!', 'alert')
        }
      }
    })
  })

  $('body').on('click', '.add-cohort-participants-button', (e) => {
    const { url, bulkUploadUrl } = e.currentTarget.dataset

    return Swal.fire({
      ...defaultSwalParams,
      showCancelButton: true,
      confirmButtonText: 'Add',
      title: 'Add participants',
      html: `
        <div class="simple-modal-body add-participants-modal">
          <p>
            Adding participants will allow them to see all the events and calls to action within this cohort.
          </p>
          <form id="add-participants-form">
            <div class="input-component-wrapper">
              <label for="colleagues-input" class="label">
                Select participants for this cohort:
              </label>
              <div class="input-wrapper user-picker">
                <select id="participants-input" multiple name="user_ids" placeholder="Start typing a colleague’s name or email"></select>
              </div>
            </div>
          </form>
          <a class="link" href="${bulkUploadUrl}">Upload participants in bulk</a>
        </div>`,
      didOpen: () => {
        const $confirmButton = $(Swal.getConfirmButton())
        userPicker($('#participants-input'), false).on('change', (e) => {
          $confirmButton.prop('disabled', !e.target.value)
        })
        $('#add-participants-form').on('submit', (e) => {
          e.preventDefault()
        })
        $confirmButton.prop('disabled', true)
      },
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const formData = $('#add-participants-form').serializeJSON()

          if (typeof formData.user_ids === 'undefined') {
            formData.user_ids = []
          } else if (typeof formData.user_ids !== 'object') {
            formData.user_ids = [formData.user_ids]
          }

          const { data } = await Axios.post(url, {
            user_ids: formData.user_ids,
            authenticity_token: window.authenticity_token,
          })
          window.flash(
            data?.data?.message ||
              `Successfully added ${formData.user_ids.length} to this cohort.`,
          )
          reloadPage()
        } catch (ex) {
          window.flash('Something went wrong!', 'alert')
        }
      }
    })
  })
})
