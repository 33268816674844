import $ from 'jquery'
import _ from 'lodash'
import Axios from 'axios'
import Swal from 'sweetalert2'

import { defaultSwalParams, escapeHTML } from './general'
import { setJSON, getJSON } from './storage'

import type { ICommunity } from 'types'

type UnknownAffiliationCheck = {
  date: number
}

const ALLY_AFFILIATES_STORAGE_KEY = 'mindrUnknownAffiliationCheck'

const getPreviousCheck = (): UnknownAffiliationCheck | null =>
  getJSON(ALLY_AFFILIATES_STORAGE_KEY)

const setPreviousCheck = (data: UnknownAffiliationCheck) =>
  setJSON(ALLY_AFFILIATES_STORAGE_KEY, data)

const getCommunitiesWithUnknownAffiliation = async (
  daysSinceSubscriberImported: number | undefined,
): Promise<ICommunity[]> => {
  const params =
    typeof daysSinceSubscriberImported === 'undefined'
      ? undefined
      : { imported_before: daysSinceSubscriberImported }
  const { data } = await Axios.get(
    '/dashboard/community_subscriptions/unknown_affiliations',
    { params },
  )

  return data.data as ICommunity[]
}

const resetAllyAffiliatesStorage = () => {
  const midnight = new Date()
  midnight.setHours(0)
  midnight.setMinutes(0)
  midnight.setSeconds(0)

  setPreviousCheck({
    date: midnight.getTime(),
  })
}

const updateAllyAffiliateForCommunity = async (
  communityId: string,
  value: string,
) => {
  try {
    const {
      data: { data },
    } = await Axios.patch(`/dashboard/community_subscriptions/${communityId}`, {
      authenticity_token: window.authenticity_token,
      _method: 'patch',
      community_subscriber: {
        ally_affiliate: value,
      },
    })

    return data
  } catch (e) {
    window.flash('Something went wrong!', 'alert')
    return { e }
  }
}

interface IUnknownAffiliationsCheckOptions {
  daysSinceLastCheck?: number
  daysSinceSubscriberImported?: number
}

const runUnknownAffiliations = async (
  opts?: IUnknownAffiliationsCheckOptions,
) => {
  const organization = window.organization
  if (!organization?.showUnknownAffiliationModal) {
    return
  }

  const daysSinceLastCheck =
    typeof opts?.daysSinceLastCheck === 'undefined'
      ? 7
      : opts.daysSinceLastCheck
  const { daysSinceSubscriberImported } = opts || {}

  const previousCheck = getPreviousCheck()
  const expiry = Date.now() - 1000 * 60 * 60 * 24 * daysSinceLastCheck

  if (!previousCheck || previousCheck.date < expiry) {
    const communities = await getCommunitiesWithUnknownAffiliation(
      daysSinceSubscriberImported,
    )

    if (communities.length) {
      const communitiesListItems = _.map(
        communities,
        (community: ICommunity) => `
          <li class="allies-affiliates-community-item">
            <div class="allies-affiliates-community-details">
              <img class="allies-affiliates-icon" src="${
                community.logo
              }" alt="Community Logo"/>
              <h3 class="allies-affiliates-name">
                ${escapeHTML(community.name)}
              </h3>
            </div>
            <div class="allies-affiliates-field input-component-wrapper">
              <label class="label">How do you identify with this community?</label>
              <div class="input-wrapper">
                <select 
                    class="allies-affiliates-select" 
                    data-community-id="${community.id}"
                    name="allies-affiliates-select-${community.id}" 
                    id="allies-affiliates-select-${community.id}">
                  <option value="">Please select an option</option>
                  <option value="affiliate">I identify with this community</option>
                  <option value="ally">I am an ally to this community</option>
                  <option value="no_designation">I would prefer not to disclose</option>
                </select>
              </div>
            </div>
          </li>
      `,
      )
      return Swal.fire({
        ...defaultSwalParams,
        title: 'One small thing...',
        html: `
          <div class="simple-modal-body allies-affiliates-modal">
            <p>
              Before you continue, ${escapeHTML(
                organization.title,
              )} would like to know if you are an ally or affiliate for the following communities.
            </p>
            <p class="disclaimer caption">
              Disclosing this is totally optional (you can select 'I would prefer not to disclose') and the information 
              will only be visible to ${escapeHTML(
                organization.title,
              )} in an anoymous, aggregated way to help ${escapeHTML(
          organization.title,
        )} understand the overall makeup of these communities.
            </p>
            <ul class="allies-affiliates-communities">
              ${communitiesListItems.join(' ')}
            </ul>
          </div>
        `,
        confirmButtonText: 'Save',
        showCancelButton: true,
        cancelButtonText: 'Remind me later',
        didOpen: () => {
          $('.allies-affiliates-select').selectize({
            allowEmptyOption: true,
          })

          // This makes sure the select dropdown renders above the modal buttons
          $('.swal2-content').css('z-index', '2')
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          const updatedItems = $('select.allies-affiliates-select')
            .filter((idx, select: HTMLSelectElement) => select.value !== '')
            .each((idx, select: HTMLSelectElement) => {
              const { communityId } = select.dataset
              const value = select.value
              updateAllyAffiliateForCommunity(communityId, value)
            })

          const remainingCount = communities.length - updatedItems.length

          if (updatedItems.length && remainingCount) {
            window.flash(
              `Thank you. We've updated ${updatedItems.length} communities, we'll remind you about the other ${remainingCount} in a week.`,
            )
          } else if (updatedItems.length) {
            window.flash(
              `Thank you. We've updated all ${updatedItems.length} communities`,
            )
          } else {
            window.flash(
              "None of your communities have been updated. We'll remind you in a week.",
            )
          }
        } else {
          window.flash(
            "No problem, none of your communities have been updated. We'll remind you in a week.",
          )
        }

        resetAllyAffiliatesStorage()
      })
    } else {
      resetAllyAffiliatesStorage()
    }
  }
}

declare global {
  interface Window {
    runUnknownAffiliations: (opts: IUnknownAffiliationsCheckOptions) => void
  }
}
window.runUnknownAffiliations = runUnknownAffiliations

$(() => {
  runUnknownAffiliations()
})
