import axios from 'axios'
import Swal, { SweetAlertResult } from 'sweetalert2'
import $ from 'jquery'

import {
  asyncForEach,
  convertUTCToLocalRange,
  defaultSwalParams,
  escapeHTML,
} from 'javascripts/general'
import userPicker from 'javascripts/user-picker'

// interfaces
import { IEventCall, IEventCallHost, ICommunity } from 'types'

// utils
import { Badge, showBadgesModal } from 'javascripts/badges'

const share = async (
  id: number,
  requestData: Record<string, string | string[]>,
  type: 'events' | 'calls' | 'communities',
) => {
  try {
    const {
      data: { data },
    } = await axios.post(`/${type}/${id}/share.json`, {
      authenticity_token: window.authenticity_token,
      ...requestData,
    })

    return data
  } catch (e) {
    window.flash('Something went wrong!', 'alert')
  }
}

const locationElements = (eventCall: IEventCall, isCall = false) => {
  return {
    inPerson: eventCall.location_in_person
      ? `<div class="location location-in-person ${isCall ? 'call' : ''}">
  <p class="text" title="${eventCall.location_in_person}">Address: ${
          eventCall.location_in_person
        }</p>
</div>`
      : '',
    virtual: eventCall.location_virtual
      ? `<div class="location location-virtual ${isCall ? 'call' : ''}">
  <p class="text">${isCall ? 'Link' : 'Dial-in link'}: <a href="${
          eventCall.location_virtual
        }" class="link" target="_blank" rel="noopener">${
          eventCall.location_virtual
        }</a></p>
</div>`
      : '',
  }
}

const hostList = (hosts: IEventCallHost[], showLimit = 3) => {
  const visibleHostElems = hosts.slice(0, showLimit).map(
    (host) => `
    <li>
      <img class="host-logo" src="${host.logo}" />
      <span class="host-name">By <strong>${escapeHTML(
        host.name,
      )}</strong></span>
    </li>
  `,
  )

  let hasMoreElem = ''
  const extraCount = hosts.length - showLimit
  if (extraCount > 0) {
    hasMoreElem = `<span class="has-more">+ ${extraCount} ${
      extraCount > 1 ? 'communities' : 'community'
    }</span>`
  }

  return `
    <div class="hosts">
      <ul>${visibleHostElems.join('')}</ul>
      ${hasMoreElem}
    </div>
  `
}

const noopModal = async (): Promise<SweetAlertResult<void>> => {
  return {
    isConfirmed: false,
    isDenied: false,
    isDismissed: true,
  }
}

const ShareModal = Swal.mixin({
  ...defaultSwalParams,
  customClass: {
    ...defaultSwalParams.customClass,
    header: 'neutral',
  },
  didOpen: () => {
    userPicker($('#colleagues-input'), true)
    $('#invitation-form').on('submit', (e) => {
      e.preventDefault()
    })
  },
})

const submitEventQuestionsModal = (
  event: IEventCall,
): Promise<SweetAlertResult<void>> => {
  const eventLocations = locationElements(event)
  const promptQuestion = event.submit_questions

  return ShareModal.fire({
    showCancelButton: true,
    showConfirmButton: promptQuestion,
    confirmButtonText: 'Submit Questions',
    cancelButtonText: promptQuestion ? 'NO QUESTIONS' : 'Close',
    title: `See you at the event, ${window.currentUser.first_name}!`,
    html: `
    <div class="simple-modal-body register-modal-body share-event-modal">
      <p>A calendar invite was sent to your email. Here are the details for this event:</p>
        <h1 class="event-title">${escapeHTML(event.title)}</h1>
        <h2 class="event-time">${convertUTCToLocalRange(
          event.datetime,
          event.duration,
        )}</h2>
        <div class="info">
          ${eventLocations.inPerson}
          ${eventLocations.virtual}
        </div>
        ${hostList(event.hosts)}
        ${
          promptQuestion
            ? `
          <form id="invitation-form" class="additional-inputs">
            <div class="input-component-wrapper">
              <label for="questions" class="label">What questions or topics would you like to be addressed at this event?</label>
              <div class="input-wrapper">
                <textarea id="questions" name="notes"></textarea>
              </div>
            </div>
            <div class="input-component-wrapper checkbox-component-wrapper">
              <label for="anonymous" class="label">Make my response anonymous</label>
              <label for="anonymous" class="input-wrapper" tabIndex="0">
                <input type="checkbox" name="anonymous" id="anonymous">
                  <div></div>
              </label>
            </div>
          </form>
        `
            : ''
        }
    </div>`,
  })
}

const shareEventModal = (
  event: IEventCall,
): Promise<SweetAlertResult<void>> => {
  const eventLocations = locationElements(event)
  return ShareModal.fire({
    showCancelButton: true,
    confirmButtonText: 'Send Invitations',
    cancelButtonText: 'NO INVITATIONS',
    title: `Get your colleagues involved, ${window.currentUser.first_name}!`,
    html: `
    <div class="simple-modal-body register-modal-body share-event-modal">
      <p>Here are the details for this event:</p>
      <h1 class="event-title">${escapeHTML(event.title)}</h1>
      <h2 class="event-time">${convertUTCToLocalRange(
        event.datetime,
        event.duration,
      )}</h2>
      <div class="info">
        ${eventLocations.inPerson}
        ${eventLocations.virtual}
      </div>
      ${hostList(event.hosts)}
      <form id="invitation-form" class="additional-inputs">
        <div class="input-component-wrapper">
          <label for="colleagues-input" class="label">
            Invite colleagues to join you at this event:
          </label>
          <div class="input-wrapper">
            <select id="colleagues-input" multiple name="user_ids" placeholder="Start typing a colleague’s name or email"></select>
          </div>
        </div>
      </form>
    </div>`,
  })
}

const shareCommunityModal = (
  community: ICommunity,
): Promise<SweetAlertResult<void>> => {
  return ShareModal.fire({
    showCancelButton: false,
    confirmButtonText: 'Submit',
    title: 'Share this community with your colleagues!',
    html: `
      <div class="simple-modal-body register-modal-body share-event-modal">
        <p>Here are the details:</p>
        <h1 class="community-title">${escapeHTML(community.name)}</h1>
        <h2 class="community-subtitle">${escapeHTML(community.subtitle)}</h2>
        <div class="info">
          ${community.allies_welcome ? `<div class="allies"></div>` : ''}
        </div>
        <form id="invitation-form" class="additional-inputs">
          <div class="input-component-wrapper">
            <label for="colleagues-input" class="label">
              Invite colleagues to join this community:
            </label>
            <div class="input-wrapper">
              <select id="colleagues-input" multiple name="user_ids" placeholder="Start typing a colleague’s name or email"></select>
            </div>
          </div>
        </form>
      </div>`,
  })
}

const shareCallModal = (
  call: IEventCall,
  bySubscribe: boolean,
): Promise<SweetAlertResult<void>> => {
  const callLocations = locationElements(call, true)
  return ShareModal.fire({
    showCancelButton: true,
    title: `${
      bySubscribe
        ? 'Thanks for getting involved'
        : 'Get your colleagues involved'
    }, ${escapeHTML(window.currentUser.first_name)}!`,
    cancelButtonText: 'NO INVITATIONS',
    confirmButtonText: 'Send Invitations',
    html: `
      <div class="simple-modal-body register-modal-body share-event-modal">
        <p>Here are the details for this call to action:</p>
        <h1 class="call-title">${escapeHTML(call.title)}</h1>
        <h2 class="call-time">Estimated Time Commitment: ${call.time}</h2>
        <div class="info">
          ${callLocations.inPerson}
          ${callLocations.virtual}
        </div>
        ${hostList(call.hosts)}
        <form id="invitation-form" class="additional-inputs">
          <div class="input-component-wrapper">
            <label for="colleagues-input" class="label">
              Invite colleagues to join you in participating in this call to action:
            </label>
            <div class="input-wrapper">
              <select id="colleagues-input" multiple name="user_ids" placeholder="Start typing a colleague’s name or email"></select>
            </div>
          </div>
        </form>
      </div>`,
  })
}

export const showShareModal = (
  data: IEventCall | ICommunity,
  type: 'events' | 'calls' | 'communities',
  bySubscribe?: boolean,
): void => {
  const fireModals = (
    selectedType: string,
  ): Promise<SweetAlertResult<void>> => {
    switch (selectedType) {
      case 'communities':
        return data.invitable
          ? shareCommunityModal(data as ICommunity)
          : noopModal()
      case 'events':
        const event = data as IEventCall
        if (bySubscribe) return submitEventQuestionsModal(event)
        else
          return event.invitable
            ? shareEventModal(data as IEventCall)
            : noopModal()
      case 'calls':
        const call = data as IEventCall
        return call.invitable ? shareCallModal(call, bySubscribe) : noopModal()
    }
  }

  fireModals(type).then(async ({ isConfirmed }) => {
    if (isConfirmed) {
      const formData = $('#invitation-form').serializeJSON()

      if (typeof formData.user_ids === 'undefined') {
        formData.user_ids = []
      } else if (typeof formData.user_ids !== 'object') {
        formData.user_ids = [formData.user_ids]
      }

      if (formData.anonymous) {
        formData.anonymous = true
      }

      const { message, new_badges } = await share(data.id, formData, type)

      if (new_badges) {
        await asyncForEach(new_badges, async (badge) => {
          await showBadgesModal(badge as Badge, true)
        })
      }

      if (bySubscribe) {
        if (type === 'events') {
          const event = data as IEventCall
          window.flash(
            `Success! You are now registered to ${event.title}${
              formData.notes ? ' and your suggestions were sent' : ''
            }.`,
          )
        } else if (type === 'calls') {
          const call = data as IEventCall
          window.flash(
            `Success! You have marked ${call.title}${
              formData.user_ids.length
                ? ` as complete and ${formData.user_ids.length} ${
                    formData.user_ids.length === 1 ? 'person' : 'people'
                  } successfully invited`
                : 'as complete'
            }.`,
          )
        }
      } else if (message) {
        window.flash(message)
      }
    } else if (bySubscribe && type === 'events') {
      const eventCall = data as IEventCall
      window.flash(`Success! You are now registered to ${eventCall.title}.`)
    } else if (bySubscribe && type === 'calls') {
      const eventCall = data as IEventCall
      window.flash(`Success! You have marked ${eventCall.title} as complete.`)
    } else if (!data.invitable) {
      switch (type) {
        case 'communities':
          window.flash('You cannot invite users to this community.', 'alert')
          return
        case 'events':
          window.flash('You cannot invite users to this event.', 'alert')
          return
        case 'calls':
          window.flash(
            'You cannot invite users to this call to action.',
            'alert',
          )
          return
      }
    }
  })
}
