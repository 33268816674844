import $ from 'jquery'
import axios from 'axios'
import Swal from 'sweetalert2'
import { asyncForEach, defaultSwalParams } from 'javascripts/general'
import { showShareModal } from 'javascripts/share-modals'

import { Badge, showBadgesModal } from './badges'

// services
const toggleRegister = async (
  register: boolean,
  id: number,
  isCall: boolean,
  update_recurring_events?: boolean,
) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `/${isCall ? 'calls' : 'events'}/${id}/${
        register ? '' : 'un'
      }register.json`,
      {
        authenticity_token: window.authenticity_token,
        update_recurring_events,
      },
    )

    return data
  } catch (e) {
    window.flash('Something went wrong!', 'alert')
  }
}

const radioSvg = `
  <svg viewBox="1 1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="8" height="8" rx="4" fill="white"/>
  </svg>
`

const RECURRING_RADIO_VALUES = {
  current: 'this',
  all: 'all',
}

$(() => {
  $('body').on('click', '.register-event-call-btn', async (e) => {
    const button = e.currentTarget
    $(button).prop('focus', false)
    const nextRegisteredValue = !$(button).hasClass('subscribed')
    const isCall = !!button.dataset.callInfo

    const toggleRegistration = async () => {
      try {
        const eventCallInfo = JSON.parse(
          button.dataset.eventInfo || button.dataset.callInfo,
        )

        const handleToggle = async (recurringRegistration?: boolean) => {
          const { new_badges } = await toggleRegister(
            nextRegisteredValue,
            eventCallInfo.id,
            isCall,
            recurringRegistration,
          )

          $(button).addClass('subscribed')

          // update card
          const wrapper = $(button).closest('.event-call-div')
          if (nextRegisteredValue && wrapper.length) {
            wrapper.find('.event').addClass('subscribed')
          }

          // update index table
          const cellWrapper = $(button).closest('.status-cell')
          if (nextRegisteredValue && cellWrapper.length) {
            cellWrapper.addClass('subscribed')
          }

          await asyncForEach(new_badges, async (badge) => {
            await showBadgesModal(badge as Badge, true)
          })

          showShareModal(eventCallInfo, isCall ? 'calls' : 'events', true)
        }

        if (nextRegisteredValue) {
          if (eventCallInfo.is_recurring) {
            Swal.fire({
              ...defaultSwalParams,
              title: 'Register to recurring event',
              width: 342,
              confirmButtonText: 'Ok',
              html: `<div class="simple-modal-body">
          <label class="input-component-wrapper checkbox-component-wrapper">
            <div class="label">This event</div>
            <div class="input-wrapper radio">
              <input type="radio" name="update-type" value=${RECURRING_RADIO_VALUES.current} checked class="update-type-input">
              ${radioSvg}
            </div>
          </label>
          <label class="input-component-wrapper checkbox-component-wrapper">
            <div class="label">All events</div>
            <div class="input-wrapper radio">
              <input type="radio" name="update-type" value=${RECURRING_RADIO_VALUES.all} class="update-type-input">
              ${radioSvg}
            </div>
          </label>
        </div>`,
            }).then(async () => {
              const recurringRegistration =
                $('input[name="update-type"]:checked').val() ===
                RECURRING_RADIO_VALUES.all
              await handleToggle(recurringRegistration)
            })
          } else {
            await handleToggle()
          }
        } else {
          const { message } = await toggleRegister(
            nextRegisteredValue,
            eventCallInfo.id,
            isCall,
          )
          window.flash(message)

          $(button).removeClass('subscribed')

          if (button.dataset.disableRegister === 'true') {
            $(button).attr('disabled', 'disabled')
          }
        }
      } catch (error) {
        $(button).removeAttr('disabled')
      }
    }

    if (nextRegisteredValue) {
      toggleRegistration()
    } else {
      const modalData = {
        event: {
          title: 'Are you sure you want to leave this event?',
          body:
            'If you leave, you will no longer be marked as attending and will not receive any updates about this event.',
        },
        call: {
          title:
            'Are you sure you want to mark this call to action as incomplete?',
          body:
            'If you do so, you will lose any badge progress associated with completion of this call to action.',
        },
      }
      const type = isCall ? 'call' : 'event'
      Swal.fire({
        ...defaultSwalParams,
        title: modalData[type].title,
        html: `<div class="simple-modal-body">
          <p>${modalData[type].body}</p>
        </div>`,
        confirmButtonText: isCall ? 'Confirm' : 'Leave',
      }).then((result) => {
        if (result.isConfirmed) {
          toggleRegistration()
        }
      })
    }
  })

  $('body').on(
    'click',
    '.share-event-call-btn, #empty-registrants-share-btn',
    (e) => {
      const button = e.currentTarget

      const eventCallInfo = JSON.parse(
        button.dataset.eventInfo || button.dataset.callInfo,
      )

      const isCall = !!button.dataset.callInfo
      showShareModal(eventCallInfo, isCall ? 'calls' : 'events')
      $('.share-event-call-btn').prop('focus', false)
    },
  )
})
