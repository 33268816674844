import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'
import moment from 'moment-timezone'
import Axios, { type Method } from 'axios'

interface IAdditionalSectionReactComponent extends HTMLElement {
  addRow: () => void
  cleanRows: () => void
}

const initCheckLeave = () => {
  const getFormValues = (form) => {
    const formData = {}
    Array.from((new FormData(form)).entries()).reduce((acc, itm) => {
      if (typeof itm[1] === 'string') {
        acc[itm[0]] = itm[1].replace(/[\r\n]+/gmi, "\n");
      } else {
        acc[itm[0]] = itm[1]
      }
      return acc
    }, formData)
    return JSON.stringify(formData)
  }

  // for each form on the page, not just the first one
  $('.check-leave-form').each((_, form) => {
    // load the initial form values, and store them for comparison later
    let initialValues = getFormValues(form)
    // check for changes in initialValues compared to current form values.
    // when leaving the page, if there are changes, make sure they want to leave.
    const beforeUnload = (e:BeforeUnloadEvent) => {
      if (initialValues !== getFormValues(form)) {
        e.returnValue = "Changes you made may not be saved."
        return e.returnValue
      }
    }
    window.addEventListener('beforeunload', beforeUnload)
    $(form).on('submit', () => {
      // if this is not a preview attempt, then remove the validation?
      // NOTE: not sure why this is needed yet. keeping it for legacy for now
      if (!JSON.parse(getFormValues(form)).preview) {
        window.removeEventListener('beforeunload', beforeUnload)
      }
    })
  })
}

$.fn.extend({
  serializeJSON: function () {
    const o = {}
    $.each(this.serializeArray(), function () {
      if (o[this.name]) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]]
        }
        o[this.name].push(this.value || '')
      } else {
        o[this.name] = this.value || ''
      }
    })
    return o
  },
})

$(() => {
  // check leave functionality
  initCheckLeave()

  // publishing
  $('#publish').on('click', (e) => {
    const form = $(e.currentTarget).closest('.mindr-form')
    if ((form[0] as HTMLFormElement).checkValidity()) {
      const { title, body, entity, paramName } = e.currentTarget.dataset

      Swal.fire({
        ...defaultSwalParams,
        title: title,
        html: `<div class="simple-modal-body"><p>${body}</p></div>`,
        confirmButtonText: 'Publish',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          form.append(
            $(
              `<input type="hidden" name="${entity}[${paramName}]" value="true">`,
            ),
          )
          form.trigger('submit')
        }
      })
    } else {
      const htmlForm = form[0] as HTMLFormElement
      htmlForm.reportValidity()
    }
  })

  // preview
  $('#preview').on('click', (e) => {
    e.preventDefault()
    const form = $(e.currentTarget).closest('.mindr-form')
    form.prepend($(`<input name="preview" type="hidden" value="true">`))
    form.attr('target', '_blank')
    form.trigger('submit')
    form.find('input[name="preview"]').remove()
    form.removeAttr('target')
  })

  // preview
  $('#survey-preview').on('click', (e) => {
    e.preventDefault()
    const form = $(e.currentTarget).closest('.mindr-form')
    form.prepend($(`<input name="survey_preview" type="hidden" value="true">`))
    form.attr('target', '_blank')
    form.trigger('submit')
    form.find('input[name="survey_preview"]').remove()
    form.removeAttr('target')
  })

  // location types
  $('#location-type').on('change', (e) => {
    $('.location-details-input').addClass('hidden')
    const value = (e.currentTarget as HTMLInputElement).value
    const locationSelectors = [
      '#event_location_attributes_address_json',
      '#event_location_attributes_room',
      '#call_location_attributes_address_json',
      '#call_location_attributes_room',
      '#meeting_location_attributes_address_json',
      '#meeting_location_attributes_room',
    ]

    const virtualSelectors = [
      '#event_location_url',
      '#call_location_url',
      '#meeting_location_url',
    ]

    // These values have been copied from Locationable::LOCATION_TYPES
    const IN_PERSON = '0'
    const VIRTUAL = '1'
    const HYBRID = '2'

    const locationFieldSelectors = (value) => {
      switch (value) {
        case HYBRID:
          return locationSelectors.concat(virtualSelectors)
        case IN_PERSON:
          return locationSelectors
        case VIRTUAL:
          return virtualSelectors
        default:
          return []
      }
    }

    $(locationFieldSelectors(value).join(', '))
      .closest('.location-details-input')
      .removeClass('hidden')
  })

  // hide dependencies
  $('#post-survey-checkbox').on('change', (e) => {
    $('.post-survey-fields')[
      (e.target as HTMLInputElement).checked ? 'removeClass' : 'addClass'
    ]('hidden')
  })

  $('#recurring-checkbox').on('change', (e) => {
    $('.recurring-fields')[
      (e.target as HTMLInputElement).checked ? 'removeClass' : 'addClass'
    ]('hidden')
  })

  $('#communication-type-select').on('change', (e) => {
    const hasValue = !!(e.target as HTMLInputElement).value
    $('.communication-name-wrapper')[hasValue ? 'removeClass' : 'addClass'](
      'hidden',
    )

    if (!hasValue) {
      $('.communication-name-wrapper input').val('')
    }
  })

  //delete section
  $('body').on('click', '.section-destroy', (e) => {
    e.preventDefault()

    const title = 'Are you sure?'
    const body =
      'Deleting this section will remove all the data within it for everyone.'
    const confirmButtonText = 'Delete'

    Swal.fire({
      ...defaultSwalParams,
      title: title,
      html: `<div class="simple-modal-body"><p>${body}</p></div>`,
      confirmButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        const section = $(e.currentTarget).closest('.additional-section')
        section.find('.additional-section-checkbox').prop('checked', true)

        // remove values
        section
          .find('.additional-section-component')
          .each((index, component) => {
            const typedComponent = component as IAdditionalSectionReactComponent
            typedComponent.cleanRows()
          })
      }
    })
  })

  // additional sections
  function changePostSurvey() {
    //fill post survey for events
    if (!$('#survey-destroy').prop('checked')) {
      const date =
        $('#event_formatted_date').val() || $('#call_formatted_date').val()
      const time =
        $('#event_formatted_time_to').val() ||
        ($('#call_formatted_date').val() ? '9:00am' : '')
      const timezone =
        $('#event_timezone').val() ||
        ($('#call_formatted_date').val() ||
        $('#call_post_survey_timezone').val()
          ? moment.tz.guess()
          : '')

      $(
        '#event_formatted_post_survey_date, #call_formatted_post_survey_date',
      ).val(date)
      $(
        '#event_formatted_post_survey_time, #call_formatted_post_survey_time',
      ).val(time)
      $(
        '#event_post_survey_timezone, #call_post_survey_timezone',
      )[0]?.selectize.setValue(timezone)
    }
  }

  $('body').on(
    'change',
    '#event_formatted_date, #call_formatted_date, #event_formatted_time_to, #event_timezone',
    changePostSurvey,
  )

  $('body').on('change', '.additional-section-checkbox', (e) => {
    if (!e.target.checked) {
      const reactComponents = $(e.currentTarget)
        .parent()
        .find('.additional-section-component')

      reactComponents.each((index, component) => {
        const typedComponent = component as IAdditionalSectionReactComponent
        typedComponent.addRow()
      })

      if ($(e.currentTarget).attr('id') === 'survey-destroy') {
        $('#post-survey-checkbox').prop('checked', true)
        const wrapper = $('#post-survey-checkbox').closest('.switch-wrapper')
        wrapper.find('p').removeClass('selected')
        wrapper.find('p.on').addClass('selected')
        $('.post-survey-fields').removeClass('hidden')

        changePostSurvey()
      }
    }
  })

  $('body').on('keypress', 'label', (e) => {
    if (e.key === 'Enter') {
      $(e.currentTarget).trigger('click')
    }
  })

  $('form').on('keypress', (e) => {
    if (e.key == 'Enter') {
      return false
    }
  })

  // complicated timing issue solution.
  // This method protects against "stampeeding" where too many of the same event
  //   are sent in succession. This will make only the "last" of the same event
  //   trigger the logic of `func`.
  const protections = {}
  function protect(name, func, delay = 300, note = '') {
    return () => {
      const prot = 'a' + Math.random() * 1000000 + '_' + Math.random() * 100000
      protections[name] = prot
      setTimeout(() => {
        if (protections[name] === prot) {
          func()
        }
      }, delay)
    }
  }

  const scrollTo = (position) => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: position,
      },
      500,
    )
  }

  const headerPlusBrandingHeight = (() => {
    const header = document.querySelector('.main .header')
    const topBar = document.querySelector(
      'div[class*="OrganizationHeaderNavWrapper"]',
    )
    return (topBar?.clientHeight || 0) + (header?.clientHeight || 0)
  })()
  const extraPaddingOnScroll = 20
  const scrollOnLoad = () => {
    if ($('label.validation-error:first()').length) {
      scrollTo(
        $('label.validation-error:first()').first().offset().top -
          (headerPlusBrandingHeight + extraPaddingOnScroll),
      )
    } else if (window.location.hash === '#meeting-minutes') {
      $('#show-meeting-details-button').trigger('click')
      scrollTo(
        $('#followups-destroy').offset().top -
          (headerPlusBrandingHeight + extraPaddingOnScroll),
      )
    }
  }
  window.addEventListener(
    'tinymce-loaded',
    protect('scrollToSomething', scrollOnLoad, 500, 'tinymce'),
  )
  protect('scrollToSomething', scrollOnLoad, 1200, 'onload')()

  $('body').on('change', '#user_agreed_to_latest_terms', () => {
    $('.submit-form-button').prop(
      'disabled',
      !$('#user_agreed_to_latest_terms').prop('checked'),
    )
  })

  // These values have been copied from DigestSendTime::FREQUENCIES
  const DIGEST_OFF = '100'
  const DIGEST_MONTHLY = '1'
  const DIGEST_WEEKLY = '0'

  const setDigestFieldNames = (container, value) => {
    const row = container.find('.date-time-row')
    row.toggleClass('digest-monthly', value === DIGEST_MONTHLY)
    row.toggleClass('digest-weekly', value === DIGEST_WEEKLY)
    row.toggleClass('digest-off', value === DIGEST_OFF)
  }

  $('body').on('change', '.digest-send-fields .digest-frequency', (e) => {
    const frequencyEl = $(e.target)
    const digestFieldsContainer = frequencyEl.closest('.digest-send-fields')

    const frequencyValue = frequencyEl.val()

    setDigestFieldNames(digestFieldsContainer, frequencyValue)
  })

  $('form.auto-submit').first().trigger('submit')

  $('body').on('click', 'button.remote', (e) => {
    e.preventDefault()
    const { url, method } = e.target.dataset

    Axios.request({
      url,
      method: method as Method,
      data: {
        authenticity_token: window.authenticity_token,
      },
    })
      .then((r) => {
        const {
          data: {
            data: { message },
          },
        } = r

        if (message) {
          window.flash(message)
        }

        location.reload()
      })
      .catch((e) => {
        if (e.response) {
          const { message } = e.response.data.data || {}

          if (message) {
            window.flash(message, 'alert')
          } else {
            window.flash('Something went wrong', 'alert')
          }
        }
      })
  })
})
