import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'
import axios from 'axios'

$(() => {
  $('#periodic-digest-switch').on('change', async (e) => {
    const nextValue = (e.target as HTMLInputElement).checked
    const { companyName, frequency } = e.target.dataset
    let shouldTriggerServer = true
    const isWeekly = frequency === 'weekly'
    const body = `The email digest is great way to stay up to date with what's happening with ${companyName}. Are you sure you want to unsubscribe?`
    try {
      if (!nextValue) {
        const { isConfirmed } = await Swal.fire({
          ...defaultSwalParams,
          title: isWeekly
            ? 'Unsubscribe from weekly email digest'
            : 'Unsubscribe from monthly email digest',
          html: `<div class="simple-modal-body"><p>${body}</p></div>`,
          confirmButtonText: 'Unsubscribe',
        })
        shouldTriggerServer = isConfirmed

        if (!isConfirmed) {
          $(e.target).prop('checked', true)
        }
      }

      if (shouldTriggerServer) {
        const formData = new FormData()

        formData.append('authenticity_token', window.authenticity_token)
        formData.append('user[without_weekly_digest]', nextValue ? '' : '1')
        await axios.patch('/dashboard/profile.json', formData)
        window.flash(
          `You successfully ${
            nextValue ? 'subscribed to' : 'unsubscribed from'
          } the ${frequency} email digest.`,
        )
      }
    } catch (e) {
      window.flash('Something went wrong!', 'error')
    }
  })
})
