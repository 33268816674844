import $ from 'jquery'
import Swal from 'sweetalert2'

import { defaultSwalParams } from 'javascripts/general'

$(() => {
  $('body').on('click', '.deactivate-button', (e) => {
    const { action, url, company } = e.currentTarget.dataset

    const modalData =
      action === 'activate'
        ? {
            title: `Activate ${company}?`,
            subtitle: `Are you sure you want to activate ${company}? This will mean that:`,
            listItems: [
              'This organization will be activated',
              'All users who were active in the organization can log in again',
              'All previously suspended users will remain suspended',
              'Super admins will still be able to access the organization',
            ],
            message: '',
            confirnButtonText: 'Confirm ACTIVATION',
            confirmTitle: `Confirm activation of ${company}`,
            confirmText: `You are activating ${company}. Please confirm that this is correct. Activation will happen immediately after confirmation.`,
            buttonClasses: 'button primary',
          }
        : {
            title: `De-activate ${company}?`,
            subtitle: `Are you sure you want to de-activate ${company}? This will mean that:`,
            listItems: [
              'This organization will be suspended',
              'All users in this organization will not be able to log in',
              'No new users can be created for this organization',
              'No emails about Mindr will be sent to users',
              'Only super admins will be able to access the organization',
            ],
            message: 'You can re-activate this organization in the future.',
            confirnButtonText: 'Confirm DEACTIVATION',
            confirmTitle: `Confirm de-activation of ${company}`,
            confirmText: `You are de-activating ${company}. Please confirm that this is correct. De-activation will happen immediately after confirmation.`,
            buttonClasses: 'button red',
          }

    const modalBody = `<div class='simple-modal-body deactivate-modal-body'>
                        <p class='subtitle'>${modalData.subtitle}</p>
                        <ul>
                          ${modalData.listItems
                            .map((item) => `<li>${item}</li>`)
                            .join('')}
                        </ul>
                        <p class='message'>${modalData.message}</p>
                       </div>`

    const confirmBody = `<form
                          class='simple-modal-body deactivate-modal-body'
                          id='deactivate-confirm-form'
                          action='${url}'
                          method='post'
                          >
                            <input name='authenticity_token' type='hidden' value='${window.authenticity_token}'>
                            <p>${modalData.confirmText}</p>
                            <p class='warning'>If you are unsure, please click CANCEL.</p>
                         </form>`

    Swal.fire({
      ...defaultSwalParams,
      title: modalData.title,
      html: modalBody,
      confirmButtonText: `Yes, ${action}`,
      customClass: {
        ...defaultSwalParams.customClass,
        confirmButton: modalData.buttonClasses,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          ...defaultSwalParams,
          title: modalData.confirmTitle,
          html: confirmBody,
          confirmButtonText: modalData.confirnButtonText,
          customClass: {
            ...defaultSwalParams.customClass,
            confirmButton: modalData.buttonClasses,
          },
          preConfirm: () => {
            const form = $('#deactivate-confirm-form')[0] as HTMLFormElement
            form.submit()
          },
        })
      }
    })
  })
})
