import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'

const RADIO_SVG = `
  <svg viewBox="1 1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="8" height="8" rx="4" fill="white"/>
  </svg>
`

$(() => {
  $('body').on('click', '.confirm-delete', (e) => {
    const {
      url,
      title = 'Delete',
      body = 'Are you sure you want to delete this entity',
      isRecurring,
      recurringObjectType = 'event',
      confirmButtonText = title,
    } = e.currentTarget.dataset

    const recurringRadios = isRecurring
      ? `
      <p>${
        recurringObjectType === 'event'
          ? 'This is a recurring event would you like to cancel:'
          : 'This is a recurring meeting would you like to cancel:'
      }</p>
      <div class="delete-recurring-radio-buttons">
        <label class="input-component-wrapper checkbox-component-wrapper">
            <div class="label">${
              recurringObjectType === 'event' ? 'This event' : 'This meeting'
            }</div>
            <div class="input-wrapper radio">
                <input type="radio" name="recurring-cancel-type" value="this" checked class="update-type-input"/>
                ${RADIO_SVG}
            </div>
        </label>
        <label class="input-component-wrapper checkbox-component-wrapper">
            <div class="label">${
              recurringObjectType === 'event'
                ? 'This and following events'
                : 'This and following meetings'
            }</div>
            <div class="input-wrapper radio">
                <input type="radio" name="recurring-cancel-type" value="all" class="update-type-input"/>
                ${RADIO_SVG}
            </div>
        </label>
    </div>`
      : ''

    Swal.fire({
      ...defaultSwalParams,
      title: title,
      html: `<div class="simple-modal-body delete-modal-body"><p>${body}</p>${recurringRadios}</div>`,
      confirmButtonText,
      cancelButtonText: 'Never mind',
    }).then((result) => {
      if (result.isConfirmed) {
        const isCancelAll =
          $('input[name="recurring-cancel-type"]:checked').val() === 'all'

        const recurringInput = isCancelAll
          ? '<input type="hidden" name="cancel_recurring_objects" value="true" />'
          : ''
        $(
          `<form method="post" action="${url}">
            <input type="hidden" name="_method" value="DELETE">
            <input type="hidden" name="authenticity_token" value="${window.authenticity_token}">
            ${recurringInput}
          </form>`,
        )
          .appendTo('body')
          .trigger('submit')
      }
    })
  })
})
