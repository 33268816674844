import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'
import axios from 'axios'

$(() => {
  $('body').on('click', '#resetPassword', () => {
    Swal.fire({
      ...defaultSwalParams,
      title: 'Reset Password',
      html: `<div class="simple-modal-body reset-password-modal">
        <div>
          <p class="body">Enter your current password then create a new password.</p>
          <form id="reset-password-form">
            <div class="input-component-wrapper">
              <label for="current_password" class="label">Current Password</label>
              <div class="input-wrapper">
                <input id="current_password" name="current_password" type="password" required />
              </div>
            </div>
            <div class="input-component-wrapper">
              <label for="password" class="label">New Password</label>
              <div class="input-wrapper">
                <input id="password" name="password" type="password" required />
              </div>
            </div>
            <div class="input-component-wrapper">
              <label for="password_confirmation" class="label">Confirm New Password</label>
              <div class="input-wrapper">
                <input id="password_confirmation" name="password_confirmation" type="password" required />
              </div>
            </div>
          </div>
          <div class="password-rules">
            Your new password must have at least:
            <ul>
              <li>12 characters</li>
              <li>1 capital letter</li>
              <li>1 lower case letter</li>
              <li>1 number</li>
              <li>1 special character</li>
            </ul>
          </div>
        </form>
      </div>`,
      didOpen: () => {
        $('#reset-password-modal').on('submit', (e) => {
          e.preventDefault()
        })
      },
      confirmButtonText: 'Reset Password',
      preConfirm: async () => {
        const form = $('#reset-password-form')[0] as HTMLFormElement
        if (form.checkValidity()) {
          try {
            await axios.post('/dashboard/profile/update_password.json', {
              authenticity_token: window.authenticity_token,
              ...$('#reset-password-form').serializeJSON(),
            })
            window.flash('You successfully changed your password.')
            return true
          } catch (e) {
            $('#reset-password-form')
              .find('input, .label')
              .removeClass('validation-error')
            $('span.validation-error-message').remove()
            $('.password-rules').removeClass('error-message')

            const errors = e.response?.data?.data?.errors

            if (errors) {
              Object.keys(errors).forEach((key) => {
                const input = $(`#${key}`)
                input.addClass('validation-error')
                input
                  .closest('.input-component-wrapper')
                  .find('.label')
                  .addClass('validation-error')
                if (key === 'password') {
                  $('.password-rules').addClass('error-message')
                } else {
                  input.after(
                    `<span class="validation-error-message">${errors[key].join(
                      ', ',
                    )}</span>`,
                  )
                }
              })
            } else {
              window.flash('Something went wrong!', 'alert')
            }
          }
        } else {
          form.reportValidity()
        }
        return false
      },
    })
  })
})
