import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'

const radioSvg = `
  <svg viewBox="1 1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="8" height="8" rx="4" fill="white"/>
  </svg>
`

$(() => {
  const form = $('.mindr-form.has-recurring-input')

  if (!form.length) {
    return
  }

  const { recurringObjectType = 'event' } = form[0].dataset

  const input = $('#recurring-object-configuration-select')
  const wrapper = input.parent('.input-wrapper')

  const startDateInput = $('[name*=formatted_date]')
  const endDateInput = $('[name*=formatted_end_date]')

  const intervalInput = wrapper.find('.interval-input')
  const frequencyInput = wrapper.find('.frequency-input')
  const updateRecurringInput = wrapper.find('.update-recurring-input')

  // set initial select value
  if (intervalInput && frequencyInput) {
    input[0]?.selectize.setValue(
      `${intervalInput.val()}:${frequencyInput.val()}`,
    )
  }

  // set recurrency
  input.on('change', (e) => {
    const [interval, frequency] = (e.target as HTMLInputElement).value.split(
      ':',
    )

    intervalInput.val(interval)
    frequencyInput.val(frequency)
    updateRecurringInput.val(frequency ? '1' : '')
  })

  // set updateRecurringInput if date changes
  $('.date-range-time-range .datepicker').on('change', () => {
    if (intervalInput.val()) {
      updateRecurringInput.val('1')
    }
  })

  form.on('submit', (e, isForceSubmit) => {
    const startDateVal = startDateInput.val().toString()
    const lastChar = Number(startDateVal[startDateVal.length - 1]) + 1
    endDateInput.val(`${startDateVal.slice(0, -1)}${lastChar}`)

    if (isForceSubmit) {
      return
    }

    if (
      !updateRecurringInput.val() &&
      intervalInput.val() &&
      frequencyInput.val()
    ) {
      e.preventDefault()

      Swal.fire({
        ...defaultSwalParams,
        title:
          recurringObjectType === 'event'
            ? 'Edit recurring event'
            : 'Edit recurring meeting',
        width: 342,
        confirmButtonText: 'Ok',
        html: `<div class="simple-modal-body">
          <label class="input-component-wrapper checkbox-component-wrapper">
            <div class="label">${
              recurringObjectType === 'event' ? 'This event' : 'This meeting'
            }</div>
            <div class="input-wrapper radio">
              <input type="radio" name="update-type" value="this" checked class="update-type-input">
              ${radioSvg}
            </div>
          </label>
          <label class="input-component-wrapper checkbox-component-wrapper">
            <div class="label">${
              recurringObjectType === 'event'
                ? 'This and following events'
                : 'This and following meetings'
            }</div>
            <div class="input-wrapper radio">
              <input type="radio" name="update-type" value="all" class="update-type-input">
              ${radioSvg}
            </div>
          </label>
        </div>`,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          if ($('input[name="update-type"]:checked').val() === 'all') {
            updateRecurringInput.val('1')
          }
          form.trigger('submit', [true])
        }
      })
    }
  })
})
