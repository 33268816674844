import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'
import axios from 'axios'

$(() => {
  $('body').on('click', '.email-leader-button', (e) => {
    const { leader, communityId } = e.currentTarget.dataset

    const parsedLeader = JSON.parse(leader)

    Swal.fire({
      ...defaultSwalParams,
      title: 'Email Leader',
      html: `<div class="simple-modal-body email-leader-modal">
        <div class="input-component-wrapper">
          <div class="label">Send To</div>
          <div class="input-wrapper">
            <div class="fake-input">${parsedLeader.user.full_name}</div>
          </div>
        </div><div class="input-component-wrapper">
          <label for="email-message" class="label">Message</label>
          <div class="input-wrapper">
            <textarea id="email-message"></textarea>
          </div>
        </div>
      </div>`,
      confirmButtonText: 'Send',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const {
            data: {
              data: { message },
            },
          } = await axios.post(`/communities/${communityId}/email.json`, {
            authenticity_token: window.authenticity_token,
            notes: $('#email-message').val(),
            user_id: parsedLeader.user.id,
          })

          if (message) {
            window.flash(message)
          }
        } catch (e) {
          window.flash('Something went wrong!', 'alert')
        }
      }
    })
  })
})
