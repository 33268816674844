import $ from 'jquery'

const showTab = (index: number) => {
  const tabs = $('.mindr-form .form-tabs .tab')
  const contents = $('.mindr-form .tab-content-wrapper .tab-content')

  tabs.removeClass('active')
  $(tabs[index]).addClass('active')

  contents.addClass('hidden')
  $(contents[index]).removeClass('hidden')
}

$(() => {
  $('.mindr-form .form-tabs').on('click', '.tab', (e) => {
    const index = $(e.currentTarget).index()
    showTab(index)
  })
})
