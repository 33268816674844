import $ from 'jquery'
import Swal from 'sweetalert2'
import Axios from 'axios'

import { defaultSwalParams, copyToClipboard } from 'javascripts/general'

$(() => {
  const reloadPage = () => {
    $('.reload-page-hack').trigger('click')
  }

  $('body').on('click', '.revoke-auth-token-button', (e) => {
    const { url } = e.currentTarget.dataset

    Swal.fire({
      ...defaultSwalParams,
      title: 'Revoke Auth Token',
      html: `<div class="simple-modal-body revoke-auth-token-modal-body">
          <p>Are you sure you want to remove this token from this organization?</p>
          <br/>
          <p>Any integrations currently using this token will no longer work.</p>
        </div>`,
      confirmButtonText: 'Revoke',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const { data } = await Axios.delete(url, {
            headers: {
              'X-CSRF-Token': window.authenticity_token,
            },
          })
          window.flash(
            data?.data?.message ||
              `Successfully revoked the auth token from this organization.`,
          )
          reloadPage()
        } catch (ex) {
          window.flash('Something went wrong!', 'alert')
        }
      }
    })
  })

  const onCopyToken = () => {
    copyToClipboard($('.copy-auth-token-field')).then(() => {
      window.flash('Token copied to your clipboard.')
    })
  }

  const onFocusToken = (e) => {
    e.target.select()
  }

  const copyAuthToken = async (token): Promise<void> => {
    await Swal.fire({
      ...defaultSwalParams,
      title: 'Copy Auth Token',
      html: `
        <div class="simple-modal-body copy-auth-token-modal-body">
          <p>Copy the token below and paste it into the app you would like to give access to Mindr Connect.</p>
          <div class="input-component-wrapper">
            <div class="input-wrapper">
              <input type="text" name="copy-auth-token" class="copy-auth-token-field" value="${token}"/>
              <button class="button secondary">Copy</button>
            </div>
          </div>
          <p>WARNING: After closing this window you will not be able to see/copy the auth token again.<p>
        </div>`,
      confirmButtonText: 'Close',
      showCancelButton: false,
      didOpen: () => {
        $('.copy-auth-token-modal-body').on('click', 'button', onCopyToken)
        $('.copy-auth-token-modal-body').on(
          'focus',
          '.copy-auth-token-field',
          onFocusToken,
        )

        $('.copy-auth-token-field').trigger('focus')
      },
      didClose: () => {
        $('.copy-auth-token-modal-body').off('click', 'button', onCopyToken)
        $('.copy-auth-token-modal-body').off(
          'focus',
          '.copy-auth-token-field',
          onFocusToken,
        )
      },
    })
  }

  $('body').on('click', '.create-organization-auth-token-button', (e) => {
    const { url } = e.currentTarget.dataset

    Swal.fire({
      ...defaultSwalParams,
      title: 'Create An Auth Token',
      html: `
        <div class="simple-modal-body create-auth-token-modal-body">
          <form id="auth-token-form">
            <div class="input-component-wrapper">
              <label for="auth-token-label" class="label">
                Label
              </label>
              <div class="input-wrapper">
                <input type="text" name="label" id="auth-token-label" placeholder="Enter a label"/>
              </div>
            </div>
          <form>
        </div>`,
      confirmButtonText: 'Create',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const formData = $('#auth-token-form').serializeJSON()
          const { data } = await Axios.post(url, {
            authenticity_token: window.authenticity_token,
            auth_token: {
              label: formData.label,
            },
          })
          await copyAuthToken(data.data.access_token)
          reloadPage()
        } catch (ex) {
          window.flash('Something went wrong!', 'alert')
        }
      }
    })
  })
})
