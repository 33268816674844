import $ from 'jquery'
import Swal from 'sweetalert2'
import { defaultSwalParams } from 'javascripts/general'
import axios from 'axios'

$(() => {
  $('body').on('click', '.edit-log-button', (e) => {
    const { url } = e.currentTarget.dataset

    Swal.fire({
      ...defaultSwalParams,
      showCancelButton: false,
      title: 'Edit Log',
      html: `<div class="simple-modal-body edit-log-modal">
        <div id="editLogModal" data-react-class="Table" data-react-props="" data-react-cache-id="Table-9999"><p class="loading-indicator">Loading...</p></div>
      </div>`,
      confirmButtonText: 'Close',
      didOpen: async () => {
        const {
          data: { data, meta },
        } = await axios.get(url)

        $('#editLogModal').attr(
          'data-react-props',
          JSON.stringify({ tableData: data, tableMeta: meta }),
        )
        window.ReactRailsUJS.mountComponents('#editLogModal')
      },
    })
  })
})
