import $ from 'jquery'
import axios from 'axios'

// utils
import { showShareModal } from 'javascripts/share-modals'
import {
  showManageNotificationsModal,
  showPostSubscriptionModal,
  showUnsubscribeConfirmation,
} from 'javascripts/subscribe-modal'
import type { IOrganization } from 'types'

declare global {
  interface Window {
    ReactRailsUJS: Record<string, (selector: string) => void>
    organization: IOrganization
  }
}

const toggleSubscriptionService = async (
  subscribe: boolean,
  id: number,
  type: 'cohort' | 'community',
) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `/${type == 'cohort' ? 'cohorts' : 'communities'}/${id}/${
        subscribe ? '' : 'un'
      }subscribe.json`,
      { authenticity_token: window.authenticity_token },
    )

    return data
  } catch (e) {
    window.flash('Something went wrong!', 'alert')
  }
}

const updateTable = (row) => {
  const table = $('#communitySubscriptionsTable')

  if (table.length) {
    const props = JSON.parse(table[0].dataset.reactProps)

    if (row.data.name) {
      props.tableData.rows.find(
        (rowData) => rowData.data.id === row.data.id,
      ).data = row.data
    } else {
      props.tableData.rows = props.tableData.rows.filter(
        (rowData) => rowData.data.id !== row.data.id,
      )
    }

    table[0].dataset.reactProps = JSON.stringify(props)

    window.ReactRailsUJS.mountComponents('#communitySubscriptionsTable')
  }
}

$(() => {
  $('body').on('click', '.subscribe-community-btn', (e) => {
    const button = e.currentTarget
    $(button).prop('focus', false)
    const nextSubscribedValue = !$(button).hasClass('subscribed')
    const communityInfo = JSON.parse(button.dataset.communityInfo)

    const toggleSubscription = async () => {
      try {
        $(button).attr('disabled', 'disabled')

        const { row, message } = await toggleSubscriptionService(
          nextSubscribedValue,
          communityInfo.id,
          communityInfo.type,
        )

        $(button)[nextSubscribedValue ? 'addClass' : 'removeClass'](
          'subscribed',
        )

        $(button).removeAttr('disabled')

        // update table
        updateTable(row)

        // update card
        const wrapper = $(button).closest('.community-card-div')
        if (nextSubscribedValue && wrapper.length) {
          wrapper.find('.community-card').addClass('subscribed')
        }

        if (nextSubscribedValue) {
          showPostSubscriptionModal(communityInfo, window.organization).then(
            ({ message, row }) => {
              if (row) {
                window.flash(
                  `Successfully subscribed to ${communityInfo.name} and saved notification preferences.`,
                )
              } else if (message) {
                window.flash(message)
              }
            },
          )
        } else {
          window.flash(message)
        }
      } catch (error) {
        $(button).removeAttr('disabled')
      }
    }

    if (nextSubscribedValue) {
      toggleSubscription()
    } else {
      showUnsubscribeConfirmation(communityInfo.type).then((result) => {
        if (result.isConfirmed) {
          toggleSubscription()
        }
      })
    }
  })

  $('body').on('click', '.edit-subscription-preferences', (e) => {
    const { communityInfo, selected } = e.currentTarget.dataset
    showManageNotificationsModal(JSON.parse(communityInfo), selected).then(
      ({ message, row }) => {
        if (row) {
          updateTable(row)
        }
        if (message) {
          window.flash(message)
        }
      },
    )
  })

  $('body').on('click', '.share-community-btn', (e) => {
    showShareModal(
      JSON.parse(e.currentTarget.dataset.communityInfo),
      'communities',
    )
    $('.share-community-btn').prop('focus', false)
  })
})
