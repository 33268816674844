import Axios from 'axios'
import _ from 'lodash'
import '@selectize/selectize'

const userPicker = (
  $select: JQuery<HTMLSelectElement>,
  excludeSelf: boolean,
): JQuery<HTMLSelectElement> => {
  let currentValueLength = 0
  $select.selectize({
    valueField: 'id',
    labelField: 'email',
    searchField: ['full_name', 'email'],
    dropdownParent: 'body',
    create: false,
    plugins: ['remove_button'],
    render: {
      option: (item) => item.full_name_html,
      item: (item) => `<div class="item">${item.full_name_html}</div>`,
    },
    onChange: (value) => {
      if (value.length > currentValueLength) {
        const inputElement = $select.closest('form').find('.selectize-input')[0]
        inputElement.scrollTop = inputElement.scrollHeight
      }
      currentValueLength = value.length
    },
    load: _.debounce((inputValue, callback) => {
      Axios.get('/users.json', {
        params: {
          'filter[*]': inputValue,
          active_only: true,
          exclude_self: excludeSelf || undefined,
        },
      }).then(({ data: { data } }) => {
        callback(
          data.rows.map((row) => ({
            ...row.data,
            title: row.data.title || '',
          })),
        )
      })
    }, 400),
  })
  return $select
}

export default userPicker
