import $ from 'jquery'

$(function () {
  let wasOpen = false
  const toggleTabs = (disable: boolean) => {
    $('.menu-body a, .menu-body button').attr('tabindex', disable ? '-1' : '0')
  }

  $(document).on('click', () => {
    $('.menu-body').removeClass('opened')
    if (wasOpen) {
      toggleTabs(true)
      wasOpen = false
    }
  })

  $('body').on('click', '.menu-toggler', (e) => {
    e.stopPropagation()
    $('.menu-body').removeClass('opened') // Close any open menus

    const wrapper = $(e.target).closest('.menu-wrapper')
    $('.menu-body', wrapper).toggleClass('opened')
    toggleTabs(false)
    wasOpen = true
  })

  toggleTabs(true)
})
